<template>
    <a-card>
        <a-page-header
            :title='"投放协议管理"'
        />
        <a-form-model
            ref="form"
            :model="form"
            v-bind='layout'
        >
            <a-row>
                <a-col :span="7">
                    <a-form-model-item label="广告主">
                        <a-input
                            v-model="form.advertiserName"
                            placeholder="请输入"
                        />
                    </a-form-model-item>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col  :span="16">
                    <a-space>
                        <a-button @click="handleReset">重置</a-button>
                        <a-button type="primary" @click="handleSearch">查询</a-button>
                        <a-button type="primary" @click="jumpDetailPage({id: 0})">创建投放协议</a-button>
                    </a-space>
                </a-col>
            </a-row>
        </a-form-model>

        <a-table
            :loading="loading"
            :columns="columns"
            :data-source="dataList"
            :row-key="(record, index) => index"
            :pagination='false'
        >
            <div slot="action" slot-scope="text, record">
                <base-button
                    :type="'link'"
                    :title="record.agreementStatus == 0 ? '审核' : '详情'"
                    @onClickBtn="jumpDetailPage(record)"
                ></base-button>
                <base-button
                    :type="'link'"
                    :title="'删除'"
                    @onClickBtn="deleteProtocol(record.id)"
                    v-if="record.showStatus == 1"
                ></base-button>
            </div>
        </a-table>

        <base-pagination
            :currentPage="pagination.current"
            :pageSize="pagination.pageSize"
            :total="pagination.total"
            @onChange="handlePaginationChange"
            @onShowSizeChange="handlePaginationChange"
        />
    </a-card>
</template>

<script>
export default {
    data() {
        return {
            layout: {
                labelCol: { span: 8 },
                wrapperCol: { span: 16 }
            },
            form: {},
            loading: false,
            columns: [
                {
                    align: 'center',
                    title: '审核通过时间',
                    dataIndex: 'auditTime',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '广告主',
                    dataIndex: 'advertiserName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '投放周期',
                    customRender:(text, row) => {
                        return `${row.deliveryStartTime || '-'} ~ ${row.deliveryEndTime || '-'}` 
                    }
                },
                {
                    align: 'center',
                    title: '协议状态',
                    customRender:(text, row) => {
                        // return row.confirmStatus == 1 ? '待确认' : row.confirmStatus == 2 ? '手动确认' : '自动确认'
                        let txt = <div class="red">待审核</div>
                        return row.agreementStatus == 0 ? txt : row.agreementStatus == 1 ? '待确认' :row.agreementStatus == 2 ? '执行中' : row.agreementStatus == 4 ? '已结束' : '-'
                    }
                },
                {
                    align: 'center',
                    title: '显示',
                    customRender:(text, row) => {
                        return row.showStatus == 1 ? '是' : '否'
                    }
                },
                {
                    align: 'center',
                    title: '操作',
                    scopedSlots: { customRender: 'action' }
                },
            ],
            dataList: [],
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0
            },
        }
    },
    created() {
        this.getDataList()
    },
    methods: {
        handleSearch() {
            this.pagination.current = 1
            this.getDataList()
        },
        handleReset() {
            this.form = {}
            this.pagination.current = 1
            this.pagination.pageSize = 10
            this.getDataList()
        },
        handlePaginationChange(current, pageSize) {
            this.pagination.current = current
            this.pagination.pageSize = pageSize
            this.getDataList()
        },
        getDataList() {
            this.loading = true
            const _form = this.form
            let params = Object.assign(_form, {
                page: this.pagination.current,
                size: this.pagination.pageSize
            })
            this.$api.core.deliverManage.getAdDeliveryAgreementList(params).then((res) => {
                this.loading = false
                if(res.code == 200) {
                    this.dataList = res.data.list || []
                    this.pagination.total = res.data.total || 0
                } else {
                    this.dataList = []
                    this.pagination.total = 0
                    console.error(`获取列表失败${res}`)
                }
            })
        },
        jumpDetailPage(record) {
            this.$router.push({
                path: `/deliverManage/detail`,
                query: {
                    id: record.id,
                    agreementStatus: record.agreementStatus
                }
            })
        },
        deleteProtocol(id) {
            let that = this
            this.$confirm({
                title: '确定删除改投放协议吗？',
                okText: '确定',
                cancelText: '取消',
                onOk () {
                    that.$api.core.deliverManage.updateAdvertiserAgreementStatus( { id: id, showStatus: 0 }).then(res => {
                        if(res.code == 200) {
                            that.$message.success('删除成功')
                            that.getDataList()
                        } else {
                            that.$message.error('删除失败')
                        }
                    })
                }
            })
        },
    }
}
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
    padding-left: 0;
    padding-right: 0;
}
</style>